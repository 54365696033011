<template>
  <div class="content-page">
    <div class="content-form" ref="form">
      <div class="content">
        <div class="center-bar">
          <slot name="formBar" />
          <div class="content-main">
            <slot name="formMsg" />
            <div class="content-party">
              <div v-if="noDataShow">
                <div class="noDataImg" style="height: 160px" v-if="showpic">
                  <div class="noDataFont">
                    {{ $g("noDataText") }}
                  </div>
                </div>
              </div>
              <div v-for="(item, index) in viewData" :key="index">
                <div class="content-title">
                  {{ item.name }}
                </div>
                <div class="content-group">
                  <k-form-group
                    label-suffix="position"
                    label-for="input-1"
                    label-align-md="right"
                    label-cols-md="3"
                    content-cols-lg="6"
                    content-cols-md="7"
                  >
                    <k-form-input v-model="item.position" :plaintext="true" />
                  </k-form-group>
                  <k-form-group
                    label-suffix="cellPhone"
                    label-for="input-1"
                    label-align-md="right"
                    label-cols-md="3"
                    content-cols-lg="6"
                    content-cols-md="7"
                  >
                    <k-form-input v-model="item.cellPhone" :plaintext="true" />
                  </k-form-group>
                  <k-form-group
                    label-suffix="email"
                    label-for="input-1"
                    label-align-md="right"
                    label-cols-md="3"
                    content-cols-lg="6"
                    content-cols-md="7"
                  >
                    <k-form-input v-model="item.email" :plaintext="true" />
                  </k-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getViewSupervisorContact } from "@/api/workAssignments";
export default {
  props: {
    electionId: {
      type: String,
      default() {
        return null;
      },
    },
    eleJobId: {
      type: String,
      default() {
        return null;
      },
    },
    elePollingPlaceId: {
      type: String,
      default() {
        return null;
      },
    },
    showpic: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      viewData: [],
      noDataShow: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getViewSupervisorContact({
        electionId: this.electionId,
        eleJobId: this.eleJobId,
        elePollingPlaceId: this.elePollingPlaceId,
      }).then((result) => {
        this.viewData = result.data;
        if (this.viewData.length == 0) {
          this.noDataShow = true;
        }
      });
    },
  },
};
</script>
  
  <style>
</style>