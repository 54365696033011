<template>
  <div>
    <k-alert-msg ref="lblMsg" />

    <k-form-group
      label-suffix="message"
      label-class="require-mark"
      label-for="message"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
    >
      <KValidationObserver ref="sendMessageObserver">
        <KValidationProvider
          mode="aggressive"
          :rules="{ required: true, max: 2000 }"
          v-slot="{ errors }"
          slim
          name="reason"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-textarea v-model="messageInfo" type="text" rows="4" />
        </KValidationProvider>
      </KValidationObserver>
    </k-form-group>
    <div class="wapBtnCenter text-md-right btnBar">
      <k-button @click="sendMessage" class="mr-3"> buttons.send </k-button>
      <k-button @click="hidePop" variant="utility"> buttons.cancel </k-button>
    </div>
    <div v-if="messageHistoryList.length > 0">
      <div
        class="cardTitle pl-0 pb-2 mb-3"
        style="font-size: 1.1rem !important"
      >
        {{ $g("messageHistory") }}
      </div>
      <template v-for="(item, index) in messageHistoryList">
        <div :key="index" class="contentParty">
          <div class="featuredFont">{{ item.sendTime }}</div>
          <div>{{ item.messageInfo }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { sendAMessage, getMessageHistoryList } from "@/api/workAssignments";
import { repResult } from "fe-infrastractures";

export default {
  props: {
    popId: {
      type: String,
      default() {
        return "";
      },
    },
    propsData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      messageInfo: "",
      messageHistoryList: [],
    };
  },
  methods: {
    hidePop() {
      this.$bvModal.hide(this.popId);
    },
    async sendMessage() {
      var isValid = await this.$refs.sendMessageObserver.validate();
      if (isValid) {
        var data = {
          pollId: "00000000-0000-0000-0000-000000000000",
          electionId: this.propsData.electionId,
          eleJobId: this.propsData.eleJobId,
          elePollingPlaceId: this.propsData.elePollingPlaceId,
          pollingPlaceTypeId: this.propsData.pollingPlaceTypeId,
          messageInfo: this.messageInfo,
        };
        sendAMessage(data).then((res) => {
          if (res.data.status == repResult.success) {
            this.$emit("sendSuccess", res);
            this.hidePop();
          } else {
            this.$refs.lblMsg.message(res.data.status, res.message);
          }
        });
      }
    },
  },
  mounted() {
    var data = {
      pollId: "00000000-0000-0000-0000-000000000000",
      electionId: this.propsData.electionId,
      eleJobId: this.propsData.eleJobId,
      elePollingPlaceId: this.propsData.elePollingPlaceId,
      pollingPlaceTypeId: this.propsData.pollingPlaceTypeId,
    };
    getMessageHistoryList(data).then((res) => {
      this.messageHistoryList = res.data;
    });
  },
};
</script>
<style lang="scss" scoped>
.btnBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 14px;
  border-top: 1px solid #dee2e6;
}
</style>
