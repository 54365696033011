<template>
  <div class="backgroundGray">
    <k-alert-msg ref="lblMsg" />
    <KValidationObserver
      ref="observer"
      class="content-page"
    >
      <k-form-group
        label-class="require-mark"
        label-suffix="election"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{
            required: true,
          }"
          v-slot="{ errors }"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            :options="electionList"
            v-model="formData.electionId"
            @change="getElectionId"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-class="require-mark"
        label-suffix="position"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
          name="position"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-model="formData.eleJobId"
            :options="positionOptions"
            @change="bindWorkLocationOptions"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        v-if="
          getConfigVal(
            curPageConfigAndDefaultPageConfig,
            'positionCodeInput',
            'vIf'
          )
        "
        label-suffix="positionCode"
        id="positionCodeInput"
        content-cols-lg="6"
        content-cols-md="7"
        label-align-md="right"
        label-cols-md="3"
        label-for="noneBind"
      >
        <k-form-input
          :plaintext="true"
          class="mt-lg-2"
          v-model="positionCode"
        />
      </k-form-group>
      <k-form-group
        label-class="require-mark"
        label-suffix="locationType"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
          name="locationType"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-model="pollingPlaceTypeId"
            :options="locationTypeOptions"
            @change="bindWorkLocationOptions"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-class="require-mark"
        label-suffix="workLocation"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
          name="workLocation"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-multiselect
            v-show="hasWorkLocation"
            ref="workLocation"
            v-model="formData.elePollingPlaceId"
            :multiple="multiAssignmentPosition"
            :asyn-search-api="WorkLocationListApi"
            :expand-query="{
              electionId: formData.electionId,
              placeType: pollingPlaceTypeId,
              eleJobId: formData.eleJobId,
              currentSelectedIds: currentSelectedIds,
            }"
            :expand-options-attributes="['pollingPlaceTypeId']"
            :on-item-select="onWorkLocationChange"
            :on-item-remove="onWorkLocationRemove"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        v-if="isShowWorkDateTime && !multiAssignmentPosition"
        label-suffix="totalFilledTarget"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <div
          class="col-form-label"
          v-if="
            assignmentTarget.unit ==
              positionFormulaUnit.assignmentsOverallCompletePeriod
          "
        >
          <span
            :class="
              totalFilledComplete >= assignmentTarget.target ? 'text-green' : 'text-red'
            "
          >{{ totalFilledComplete }}</span>
          /
          {{
            assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
              assignmentTarget.unit == positionFormulaUnit.hoursPerDay
              ? assignmentTarget.target.toFixed(2)
              : assignmentTarget.target
          }}
          {{ assignmentTarget.unitShowTypeStr }}
        </div>
        <div
          class="col-form-label"
          v-else
        >
          <span
            :class="
              totalFilled >= assignmentTarget.target ? 'text-green' : 'text-red'
            "
          >{{ totalFilled }}</span>
          /
          {{
            assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
              assignmentTarget.unit == positionFormulaUnit.hoursPerDay
              ? assignmentTarget.target.toFixed(2)
              : assignmentTarget.target
          }}
          {{ assignmentTarget.unitShowTypeStr }}
        </div>
      </k-form-group>
      <k-form-group
        v-if="isShowWorkDateTime && !multiAssignmentPosition"
        label-class="require-mark"
        label-suffix="workDateTime"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="9"
        content-cols-md="9"
      >
        <div class="overflow-auto">
          <k-alert
            :show="isShowWorkDateTimeRequired"
            variant="warning"
          >
            {{ $g("validate.required") }}
          </k-alert>
          <div
            :key="item.eleJobTitleAssignmentTargetId"
            v-for="item in assignmentTarget.workDayList"
          >
            <div
              class="d-flex align-items-center mb-2 wapSate"
              v-if="item.isCheck || item.filled < item.target"
            >
              <div>
                <k-form-checkbox
                  :disabled="
                    !!(
                      item.eleJobTitleAssignmentId ||
                      !item.isEnable ||
                      assignmentTarget.unit ==
                      positionFormulaUnit.assignmentsOverallCompletePeriod
                    )
                  "
                  v-model="item.isCheck"
                  @change="checkboxChange"
                />
              </div>
              <div class="mr-3">
                {{ item.workDate }}
              </div>
              <div class="mr-2">
                <KValidationProvider
                  v-slot="{ errors }"
                  :name="`startTime-${item.eleJobTitleAssignmentTargetId}`"
                  :rules="
                    item.isCheck
                      ? {
                        required: true,
                        validateTimeFormat: true,
                        validateStartTimeAndEndTime: item.toTime,
                      }
                      : {}
                  "
                  :ref="`startTime-${item.eleJobTitleAssignmentTargetId}`"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-timepicker
                    class="datepicker-time"
                    @onTimeChange="onStartTimeChange(item)"
                    v-model="item.fromTime"
                  />
                </KValidationProvider>
              </div>
              <div class="mr-1">
                -
              </div>
              <div class="mr-2 ml-2">
                <KValidationProvider
                  v-slot="{ errors }"
                  :name="`endTime-${item.eleJobTitleAssignmentTargetId}`"
                  :rules="
                    item.isCheck
                      ? {
                        required: true,
                        validateTimeFormat: true,
                      }
                      : {}
                  "
                  :ref="`endTime-${item.eleJobTitleAssignmentTargetId}`"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-timepicker
                    class="datepicker-time"
                    @onTimeChange="onEndTimeChange(item)"
                    v-model="item.toTime"
                  />
                </KValidationProvider>
              </div>
              <div
                class="mr-3"
                v-if="assignmentTarget.showHalfDay"
              >
                <k-form-select
                  v-model="item.eleJobTitleWorkDayShiftId"
                  :options="workDateShiftList"
                  @change="eleJobTitleWorkDayShiftChange(item)"
                />
              </div>
              <div
                v-if="
                  assignmentTarget.unit != positionFormulaUnit.hoursOverall &&
                    assignmentTarget.unit !=
                    positionFormulaUnit.assignmentsOverallCompletePeriod &&
                    assignmentTarget.unit !=
                    positionFormulaUnit.assignmentsOverallDayToDay
                "
              >
                <span
                  :class="
                    parseFloat(itemFilled(item)) >= item.target
                      ? 'text-green'
                      : 'text-red'
                  "
                >{{ itemFilled(item) }}</span>
                /
                {{
                  assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
                    assignmentTarget.unit == positionFormulaUnit.hoursPerDay
                    ? item.target.toFixed(2)
                    : item.target
                }}
                {{ assignmentTarget.unitShowTypeStr }}
              </div>
            </div>
          </div>
        </div>
      </k-form-group>
      <template
        v-if="multiAssignmentPosition"
        v-for="(item, index) in assignmentTargetMulti"
      >
        <FormWorkDateTime
          :assignment-target="item.assignmentTarget"
          :title="item.title"
          :key="index"
          :ref="`FormWorkDateTime${index}`"
          :position-formula-unit="positionFormulaUnit"
        />
      </template>
    </KValidationObserver>
  </div>
</template>

<script>
import { getWorkDateShifts } from "@/api/common";

export const positionFormulaUnit = {
  unknown: 0,
  hoursPerDay: 1,
  hoursOverall: 2,
  assignmentsPerDay: 4,
  assignmentsOverallDayToDay: 8,
  assignmentsOverallCompletePeriod: 16,
};
import {
  getLocationTypeList,
  getElectionList,
  getElePositionList,
  getWorkLocationList,
  getAssignmentTarget,
  getAssigndWorkerConfiguration,
} from "@/api/workAssignments";
import {
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import FormWorkDateTime from "./addAssignWork/formWorkDateTime.vue";

export default {
  components: {
    FormWorkDateTime,
  },
  computed: {
    totalFilled(){
      let total = 0;
      this.assignmentTarget?.workDayList?.forEach((item) => {
        let itemFilled = parseFloat(this.itemFilled(item));
        total += itemFilled;
      });
      if(
          this.assignmentTarget.unit ==
          positionFormulaUnit.assignmentsOverallCompletePeriod
      ){
        return this.assignmentTarget.filled;
      } else if(
          this.assignmentTarget.unit == positionFormulaUnit.assignmentsPerDay ||
          this.assignmentTarget.unit ==
          positionFormulaUnit.assignmentsOverallDayToDay
      ){
        return total;
      } else {
        return total == 0 ? 0 : total.toFixed(2);
      }
    },
    WorkLocationListApi(){
      return getWorkLocationList;
    },
    hasWorkLocation(){
      return (
          this.formData.electionId &&
          this.formData.eleJobId &&
          this.pollingPlaceTypeId
      );
    },
    currentSelectedIds() {
      if (this.multiAssignmentPosition && this.formData.elePollingPlaceId) {
        return this.formData.elePollingPlaceId;
      }
      if (!this.multiAssignmentPosition && this.formData.elePollingPlaceId) {
        return [this.formData.elePollingPlaceId];
      }
      return null;
    },
    totalFilledComplete() {
      let total = 0;
      this.assignmentTarget?.workDayList?.some((item) => {
        let itemFilled = parseFloat(this.itemFilled(item));
        total = itemFilled;
        return true;
      });
      return total;
    },
  },
  data(){
    let curPageConfigAndDefaultPageConfig =
        getCurPageConfigAndDefaultPageConfigbyRoutePath(
            "workerPortal",
            this.$route.path,
        );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      multiAssignmentPosition: false,
      assignmentTargetMulti: [],
      formData: {
        electionId: "",
        eleJobId: "",
        pollingPlaceTypeId: "",
        elePollingPlaceId: "",
        unitShowType: "",
      },
      pollingPlaceTypeId: "",
      assignmentTarget: {
        unit: 0,
        workDayList: [],
        filled: 0,
        target: 0,
        showHalfDay: true,
        halfDays: [],
        unitShowTypeStr: "",
        unitShowType: 0,
      },
      positionFormulaUnit,
      electionList: [],
      positionOptions: [],
      locationTypeOptions: [],
      workLocationOptions: [],
      isShowWorkDateTimeRequired: false,
      isShowWorkDateTime: false,
      AssigndWorkerConfiguration: "",
      workDateShiftList: [],
      positionCode: "",
    };
  },
  methods: {
    eleJobTitleWorkDayShiftChange(item, first = false){
      this.workDateShiftList.forEach((it) => {
        if(item.eleJobTitleWorkDayShiftId == it.value){
          if(it.relateContent && !first){
            item.fromTime = it.relateContent;
          }
          if(it.relateContent2 && !first){
            item.toTime = it.relateContent2;
          }
          item.shiftType = it.label;
          if (first) {
            if (it.label != "1") {
              //Shift Type no Full Day
              item.initCount = 0.5;
            } else {
              //Shift Type is Full Day
              item.initCount = 1;
            }
          } else {
            this.itemFilled(item);
          }
          if(this.assignmentTarget.unit ==
            positionFormulaUnit.assignmentsOverallCompletePeriod){
            this.assignmentTarget?.workDayList?.forEach((item2) => {
              if(item2.eleJobTitleWorkDayShiftId != it.value){
                item2.eleJobTitleWorkDayShiftId = it.value
                if (it.relateContent) {
                  item2.fromTime = it.relateContent;
                }
                if (it.relateContent2) {
                  item2.toTime = it.relateContent2;
                }
                item2.shiftType = it.label;
                if (first) {
                  if (it.label != "1") {
                    //Shift Type no Full Day
                    item2.initCount = 0.5;
                  } else {
                    //Shift Type is Full Day
                    item2.initCount = 1;
                  }
                } else {
                  this.itemFilled(item2);
                }
              }
              if(!item2.eleJobTitleAssignmentId){
                // no eleJobTitleAssignment unallocated
                item2.initCount = 0
              }
            });
          }
        }
      });
    },
    checkboxChange(val){
      if(val){
        this.isShowWorkDateTimeRequired = false;
      } else {
        if(
            this.assignmentTarget.workDayList.filter((item) => item.isCheck)
                .length == 0
        ){
          this.isShowWorkDateTimeRequired = true;
        }
      }
    },
    itemFilled(item){
      let canAdd =
          item.isCheck &&
          item.isValid &&
          item.fromTime &&
          item.toTime &&
          item.fromTime != this.INVALID_DATE &&
          item.toTime != this.INVALID_DATE &&
          item.toTime > item.fromTime;
      if(
          this.assignmentTarget.unit == positionFormulaUnit.hoursPerDay ||
          this.assignmentTarget.unit == positionFormulaUnit.hoursOverall
      ){
        return (
            canAdd
                ? item.filled -
                item.eleJobTitleAssignmentFilled +
                (item.toTime - item.fromTime) / 3600000
                : item.filled
        ).toFixed(2);
      } else {
        //Shift Type is Full Day
        let conut = 1;
        if (item.shiftType != "1") {
          //Shift Type no Full Day
          conut = 0.5;
        }
        return canAdd ? item.filled - item.initCount + conut : item.filled;
      }
    },
    onEndTimeChange(item){
      setTimeout(() => {
        this.$refs[
            `startTime-${item.eleJobTitleAssignmentTargetId}`
            ][0].validate();
      }, 100);
    },
    onStartTimeChange(item){
      this.$refs[`endTime-${item.eleJobTitleAssignmentTargetId}`][0].validate();
    },
    setMessage(message){
      this.$refs.lblMsg.message(repResult.faild, message);
    },
    getElectionId(val){
      this.assignmentTargetMulti = [];
      this.formData.eleJobId = "";
      this.formData.elePollingPlaceId = "";
      this.isShowWorkDateTime = false;
      getElePositionList({ electionId: val }).then((res) => {
        this.positionOptions = res.data;
        if(this.positionOptions.length > 0){
          this.positionOptions[0].text = "";
        }
      });
    },
    getSelectMsg(){
      getElectionList().then((res) => {
        this.electionList = res.data;
        if(this.electionList.length > 0){
          this.electionList[0].text = "";
        }
      });
      getLocationTypeList().then((res) => {
        this.locationTypeOptions = res.data;
      });
    },
    bindWorkLocationOptions(){
      this.assignmentTargetMulti = [];
      let option = this.positionOptions.find(
          (c) => c.value == this.formData.eleJobId,
      );
      this.positionCode = option.code;
      this.multiAssignmentPosition = option?.multiAssignmentPosition ?? false;
      this.formData.elePollingPlaceId = "";
      this.isShowWorkDateTime = false;
      if(
          this.formData.electionId &&
          this.formData.eleJobId &&
          this.pollingPlaceTypeId
      ){
        this.$refs.workLocation.doSearch("");
      } else {
        this.formData.elePollingPlaceId = "";
        this.workLocationOptions = [];
        this.assignmentTarget = {
          unit: 0,
          workDayList: [],
          filled: 0,
          target: 0,
          showHalfDay: true,
          halfDays: [],
          unitShowTypeStr: "",
          unitShowType: 0,
        };
      }
      getWorkDateShifts({
        eleJobId: this.formData.eleJobId,
      }).then((resp) => {
        this.workDateShiftList = resp.data || [];
      });
    },
    checkValid(){
      if(this.AssigndWorkerConfiguration == 2){
        return this.$refs.observer.validate();
      }

      if(this.multiAssignmentPosition){
        return this.$refs.observer.validate() && this.checkValidDetialMulti();
      }
      return this.$refs.observer.validate() && this.checkValidDetial();
    },
    async checkValidDetialMulti(){
      let verify_int = 0;
      let length = this.assignmentTargetMulti.length;
      for (const index in this.assignmentTargetMulti) {
        if(
            await this.$refs[`FormWorkDateTime${index}`][0].checkValidDetial()
        ){
          verify_int += 1;
        } else {
          verify_int -= 1;
        }
      }
      return verify_int == length;
    },
    checkValidDetial(){
      let valid = true;
      if(this.multiAssignmentPosition){
        valid = this.checkValidDetialMulti();
        return valid;
      }
      let isOverTheTarget = false;
      if(
          this.assignmentTarget.unit == positionFormulaUnit.hoursPerDay ||
          this.assignmentTarget.unit == positionFormulaUnit.hoursOverall
      ){
        this.assignmentTarget.workDayList.forEach((item) => {
          if(
              item.isCheck &&
              item.filled -
              item.eleJobTitleAssignmentFilled +
              (item.toTime - item.fromTime) / 3600000 >
              item.target
          ){
            this.$refs.lblMsg.message(
                repResult.faild,
                this.$g("overTheTarget"),
            );
            isOverTheTarget = true;
          }
        });
      } else {
        if(this.totalFilled > this.assignmentTarget.target){
          this.$refs.lblMsg.message(repResult.faild, this.$g("overTheTarget"));
          isOverTheTarget = true;
        }
      }
      if(
          this.assignmentTarget.workDayList.filter((item) => item.isCheck)
              .length == 0
      ){
        this.isShowWorkDateTimeRequired = true;
        return false;
      }
      if(isOverTheTarget){
        return false;
      } else {
        return this.$refs.observer.validate();
      }
    },
    getFormData(){
      if(this.multiAssignmentPosition){
        let requestModel = { requestModel: [] };
        this.assignmentTargetMulti.forEach((c) => {
          this.formData.unit = c.assignmentTarget.unit;
          this.formData.pollingPlaceTypeId =
              c.assignmentTarget.pollingPlaceTypeId;
          this.formData.workDayList = c.assignmentTarget.workDayList.filter(
              (item) => item.isCheck,
          );
          requestModel.requestModel.push({
            ...this.formData,
            elePollingPlaceId: c.id,
          });
        });
        return requestModel;
      }
      this.formData.unit = this.assignmentTarget.unit;
      this.formData.pollingPlaceTypeId =
          this.assignmentTarget.pollingPlaceTypeId;
      this.formData.workDayList = this.assignmentTarget.workDayList.filter(
          (item) => item.isCheck,
      );
      return this.formData;
    },
    onWorkLocationRemove(v){
      this.assignmentTargetMulti = this.assignmentTargetMulti.filter(
          (c) => c.id != v.value,
      );
      if(
          this.multiAssignmentPosition &&
          this.formData.elePollingPlaceId.length == 1
      ){
        this.$refs.workLocation.curOptions.forEach((c) => {
          c.$isDisabled = false;
        });
      }
    },
    onWorkLocationChange(v){
      let elePollingPlaceId = v.value;
      if(
          this.multiAssignmentPosition &&
          (this.formData.elePollingPlaceId.length == 0 ||
              !this.formData.elePollingPlaceId)
      ){
        this.$refs.workLocation.curOptions.forEach((c) => {
          if(
              c.pollingPlaceTypeId != v.pollingPlaceTypeId &&
              c.value != v.value
          ){
            c.$isDisabled = true;
          }
        });
      }
      getAssignmentTarget({
        eleJobId: this.formData.eleJobId,
        elePollingPlaceId: elePollingPlaceId,
      }).then((resp) => {
        if(this.multiAssignmentPosition){
          resp.data.forEach((c) => {
            this.assignmentTargetMulti.push({
              assignmentTarget: c,
              title: v.text,
              id: v.value,
            });
          });
        } else {
          this.isShowWorkDateTime = true;
          this.isShowWorkDateTimeRequired = false;
          this.assignmentTarget = resp.data[0];
          this.assignmentTarget.workDayList.forEach((item) => {
            if(!item.fromTime){
              item.fromTime = null;
            }
            if(!item.toTime){
              item.toTime = null;
            }
            let isbool = !!(
                item.eleJobTitleAssignmentId ||
                this.assignmentTarget.unit ==
                positionFormulaUnit.assignmentsOverallCompletePeriod
            );
            this.$set(item, "isCheck", isbool);
            this.$set(item, "shiftType", null);
            if (isbool) {
              this.eleJobTitleWorkDayShiftChange(item, true);
            } else {
              item.initCount = 0;
              this.workDateShiftList.forEach((it) => {
                if (item.eleJobTitleWorkDayShiftId == it.value) {
                  item.shiftType = it.label;
                }
              });
            }
            if(!item.eleJobTitleWorkDayShiftId){
              item.eleJobTitleWorkDayShiftId =
                  this.workLocationOptions[0].value;
            }
          });
          // if(
          //     this.assignmentTarget.unit ==
          //     positionFormulaUnit.assignmentsOverallCompletePeriod &&
          //     this.assignmentTarget.workDayList.every((item) => {
          //       return (
          //           !item.eleJobTitleAssignmentId ||
          //           item.eleJobTitleAssignmentId ==
          //           "00000000-0000-0000-0000-000000000000"
          //       );
          //     })
          // ){
          //   this.assignmentTarget.filled += 1;
          // }
        }
      });
    },
  },
  mounted(){
    getAssigndWorkerConfiguration().then((res) => {
      this.AssigndWorkerConfiguration = res.data;
    });
    this.getSelectMsg();
  },
};
</script>
<style lang="scss" scoped>
.wapSate {
  > div,
  > span {
    flex-shrink: 0;
  }
}
</style>
