<template>
  <KValidationObserver ref="observer">
    <div class="content-group">
      <k-form-group
        label-class="require-mark"
        label-suffix="election"
        label-for="nonBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{
            required: true,
          }"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            :options="electionList"
            v-model="form.eLectionId"
            @change="getElectionId"
            class="mr-3 wid"
            value="tlt"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-class="require-mark"
        label-suffix="position"
        label-for="nonBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{
            required: true,
          }"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-multiselect
            id="search-type"
            :options="JobIdList"
            v-model="form.eleJobIds"
          />
        </KValidationProvider>
      </k-form-group>
    </div>
  </KValidationObserver>
</template>

<script>
import {
  getElectionList,
  getElePositionList,
  saveAssignPosition,
} from "@/api/workAssignments";
export default {
  props: {
    eleJobTitleAssignmentTargetId: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      form: {
        eLectionId: "",
        eleJobIds: [],
      },
      electionList: [],
      JobIdList: [],
    };
  },
  methods: {
    getElectionId(val) {
      getElePositionList({ electionId: val, isAssignPosition: true }).then(
        (res) => {
          this.JobIdList = res.data;
        }
      );
    },
    async saveAction() {
      return this.$refs.observer.validate();
    },
    saveData() {
      return saveAssignPosition(this.form);
    },
    getSelectMsg() {
      getElectionList().then((res) => {
        this.electionList = res.data;
        if (this.electionList.length > 0) {
          this.electionList[0].text = "";
        }
      });
    },
  },
  mounted() {
    if (document.getElementsByClassName("multiselect__input").length > 0) {
      document.getElementsByClassName("multiselect__input")[0].ariaLabel =
        document.getElementsByClassName("multiselect__input")[0].placeholder;
      document.getElementsByClassName(
        "multiselect__placeholder"
      )[0].style.color = "#767676";
    }
    this.getSelectMsg();
  },
};
</script>
