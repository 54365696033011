<template>
  <div class="backgroundGray p-3">
    <k-alert-msg ref="lblMsg" />
    <KValidationObserver ref="observer" class="content-page">
      <k-form-group
        label-class="require-mark"
        label-suffix="position"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <k-form-input v-model="formData.eleJobTitle" :plaintext="true" />
      </k-form-group>
      <k-form-group
        v-if="
          getConfigVal(
            curPageConfigAndDefaultPageConfig,
            'positionCodeInput',
            'vIf'
          )
        "
        id="positionCodeInput"
        content-cols-lg="6"
        content-cols-md="7"
        label-align-md="right"
        label-cols-md="3"
        label-for="noneBind"
        label-suffix="positionCode"
      >
        <k-form-input :plaintext="true" v-model="formData.eleJobTitleCode" />
      </k-form-group>
      <k-form-group
        v-if="onlyPosition"
        label-class="require-mark"
        label-suffix="locationType"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <k-form-input v-model="formData.placeTypeStr" :plaintext="true" />
      </k-form-group>

      <k-form-group
        v-else
        label-class="require-mark"
        label-suffix="locationType"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
          name="locationType"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-model="formData.placeType"
            :options="locationTypeOptions"
            @change="bindWorkLocationOptions"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        v-if="onlyPosition"
        label-class="require-mark"
        label-suffix="workLocation"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <k-form-input v-model="formData.elePollingPlace" :plaintext="true" />
      </k-form-group>
      <k-form-group
        v-else
        label-class="require-mark"
        label-suffix="workLocation"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
          name="workLocation"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-multiselect
            ref="workLocation"
            v-model="formData.elePollingPlaceId"
            :options="workLocationOptions"
            :multiple="multiAssignmentPosition"
            :onItemSelect="onWorkLocationChange"
            :onItemRemove="onWorkLocationRemove"
            :asyn-search-api="WorkLocationListApi"
            :expand-query="{
              electionId: formData.electionId,
              placeType: pollingPlaceTypeId,
              eleJobId: formData.eleJobId,
              currentSelectedIds: currentSelectedIds,
            }"
            :expand-options-attributes="['pollingPlaceTypeId']"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-suffix="totalFilledTarget"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
        v-if="!multiAssignmentPosition"
      >
        <div
          class="col-form-label"
          v-if="
            assignmentTarget.unit ==
            positionFormulaUnit.assignmentsOverallCompletePeriod
          "
        >
          <span
            :class="
              totalFilledComplete >= assignmentTarget.target ? 'text-green' : 'text-red'
            "
            >{{ totalFilledComplete }}</span
          >
          /
          {{
            assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
            assignmentTarget.unit == positionFormulaUnit.hoursPerDay
              ? assignmentTarget.target.toFixed(2)
              : assignmentTarget.target
          }}
          {{ assignmentTarget.unitShowTypeStr }}
        </div>
        <div class="col-form-label" v-else>
          <span
            :class="
              totalFilled >= assignmentTarget.target ? 'text-green' : 'text-red'
            "
            >{{ totalFilled }}</span
          >
          /
          {{
            assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
            assignmentTarget.unit == positionFormulaUnit.hoursPerDay
              ? assignmentTarget.target.toFixed(2)
              : assignmentTarget.target
          }}
          {{ assignmentTarget.unitShowTypeStr }}
        </div>
      </k-form-group>
      <k-form-group
        v-if="!multiAssignmentPosition"
        label-class="require-mark"
        label-suffix="workDateTime"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="9"
        content-cols-md="9"
      >
        <div class="overflow-auto">
          <k-alert :show="isShowWorkDateTimeRequired" variant="warning">
            {{ $g("validate.required") }}
          </k-alert>
          <div
            :key="item.eleJobTitleAssignmentTargetId"
            v-for="item in assignmentTarget.workDayList"
          >
            <div
              class="d-flex align-items-center mb-2 wapSate"
              v-if="item.isCheck || item.filled < item.target"
            >
              <div>
                <k-form-checkbox
                  :disabled="
                    !!(
                      item.eleJobTitleAssignmentId ||
                      !item.isEnable ||
                      assignmentTarget.unit ==
                        positionFormulaUnit.assignmentsOverallCompletePeriod
                    )
                  "
                  v-model="item.isCheck"
                  @change="checkboxChange"
                />
              </div>
              <div class="mr-3">
                {{ item.workDate }}
              </div>
              <div class="mr-2">
                <KValidationProvider
                  v-slot="{ errors }"
                  :name="`startTime-${item.eleJobTitleAssignmentTargetId}`"
                  :rules="
                    item.isCheck
                      ? {
                          required: true,
                          validateTimeFormat: true,
                          validateStartTimeAndEndTime: item.toTime,
                        }
                      : {}
                  "
                  :ref="`startTime-${item.eleJobTitleAssignmentTargetId}`"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-timepicker
                    class="datepicker-time"
                    @onTimeChange="onStartTimeChange(item)"
                    v-model="item.fromTime"
                  />
                </KValidationProvider>
              </div>
              <div class="mr-1">-</div>
              <div class="mr-2 ml-2">
                <KValidationProvider
                  v-slot="{ errors }"
                  :name="`endTime-${item.eleJobTitleAssignmentTargetId}`"
                  :rules="
                    item.isCheck
                      ? {
                          required: true,
                          validateTimeFormat: true,
                        }
                      : {}
                  "
                  :ref="`endTime-${item.eleJobTitleAssignmentTargetId}`"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-timepicker
                    class="datepicker-time"
                    @onTimeChange="onEndTimeChange(item)"
                    v-model="item.toTime"
                  />
                </KValidationProvider>
              </div>
              <div class="mr-3" v-if="assignmentTarget.showHalfDay">
                <k-form-select
                  v-model="item.eleJobTitleWorkDayShiftId"
                  :options="workDateShiftList"
                  @change="eleJobTitleWorkDayShiftChange(item)"
                />
              </div>
              <div
                v-if="
                  assignmentTarget.unit != positionFormulaUnit.hoursOverall &&
                  assignmentTarget.unit !=
                    positionFormulaUnit.assignmentsOverallCompletePeriod &&
                  assignmentTarget.unit !=
                    positionFormulaUnit.assignmentsOverallDayToDay
                "
              >
                <span
                  :class="
                    parseFloat(itemFilled(item)) >= item.target
                      ? 'text-green'
                      : 'text-red'
                  "
                  >{{ itemFilled(item) }}</span
                >
                /
                {{
                  assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
                  assignmentTarget.unit == positionFormulaUnit.hoursPerDay
                    ? item.target.toFixed(2)
                    : item.target
                }}
                {{ assignmentTarget.unitShowTypeStr }}
              </div>
            </div>
          </div>
        </div>
      </k-form-group>
      <template
        v-else-if="multiAssignmentPosition && onlyPosition"
        v-for="(item, index) in assignmentTargetMulti"
      >
        <EditFormWorkDateTime
          :assignmentTarget="item"
          :title="item.elePollingPlaceName"
          :key="index"
          :ref="`FormWorkDateTime${index}`"
          :positionFormulaUnit="positionFormulaUnit"
        />
      </template>
      <template
        v-else-if="multiAssignmentPosition && !onlyPosition"
        v-for="(item, index) in assignmentTargetMulti"
      >
        <FormWorkDateTime
          :assignmentTarget="item.assignmentTarget"
          :title="item.title"
          :key="index"
          :ref="`FormWorkDateTime${index}`"
          :positionFormulaUnit="positionFormulaUnit"
        />
      </template>
    </KValidationObserver>
  </div>
</template>

<script>
export const positionFormulaUnit = {
  unknown: 0,
  hoursPerDay: 1,
  hoursOverall: 2,
  assignmentsPerDay: 4,
  assignmentsOverallDayToDay: 8,
  assignmentsOverallCompletePeriod: 16,
};
import {
  getAssignmentTargetForEdit,
  getAssignmentTarget,
  getWorkLocationList,
  getLocationTypeList,
  getAssigndWorkerConfiguration,
} from "@/api/workAssignments";
import { getWorkDateShifts } from "@/api/common";
import {
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import EditFormWorkDateTime from "./addAssignWork/editFormWorkDateTime.vue";
import FormWorkDateTime from "./addAssignWork/formWorkDateTime.vue";

export default {
  components: { EditFormWorkDateTime, FormWorkDateTime },
  props: {
    propsData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    totalFilled() {
      let total = 0;
      this.assignmentTarget?.workDayList?.forEach((item) => {
        if (item.fromTime != "Invalid date" && item.toTime != "Invalid date") {
          let itemFilled = parseFloat(this.itemFilled(item));
          total += itemFilled;
        }
      });
      if (
        this.assignmentTarget.unit == positionFormulaUnit.assignmentsPerDay ||
        this.assignmentTarget.unit ==
          positionFormulaUnit.assignmentsOverallDayToDay ||
        this.assignmentTarget.unit ==
          positionFormulaUnit.assignmentsOverallCompletePeriod
      ) {
        return total;
      } else {
        return total == 0 ? 0 : total.toFixed(2);
      }
    },
    totalFilledComplete() {
      let total = 0;
      this.assignmentTarget?.workDayList?.some((item) => {
        if (item.fromTime != "Invalid date" && item.toTime != "Invalid date") {
          let itemFilled = parseFloat(this.itemFilled(item));
          total = itemFilled;
          return true;
        }
      });
      return total;
    },
    WorkLocationListApi(){
      return getWorkLocationList;
    },
    currentSelectedIds() {
      if (this.multiAssignmentPosition && this.formData.elePollingPlaceId) {
        return this.formData.elePollingPlaceId;
      }
      if (!this.multiAssignmentPosition && this.formData.elePollingPlaceId) {
        return [this.formData.elePollingPlaceId];
      }
      return null;
    }
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      multiAssignmentPosition: this.propsData.multiAssignmentPosition,
      assignmentTargetMulti: [],
      formData: {
        electionId: "",
        eleJobId: "",
        pollingPlaceTypeId: "",
        elePollingPlaceId: "",
        unitShowType: "",
      },
      onlyPosition: true,
      assignmentTarget: {
        unit: 0,
        workDayList: [],
        filled: 0,
        target: 0,
        showHalfDay: true,
        halfDays: [],
        unitShowTypeStr: "",
        unitShowType: 0,
      },
      electionList: [],
      locationTypeOptions: [],
      workLocationOptions: [],
      positionFormulaUnit,
      isShowWorkDateTimeRequired: false,
      AssigndWorkerConfiguration: "",
      workDateShiftList: [],
    };
  },
  methods: {
    eleJobTitleWorkDayShiftChange(item, first = false) {
      this.workDateShiftList.forEach((it) => {
        if (item.eleJobTitleWorkDayShiftId == it.value) {
          if (it.relateContent && !first) {
            item.fromTime = it.relateContent;
          }
          if (it.relateContent2 && !first) {
            item.toTime = it.relateContent2;
          }
          item.shiftType = it.label;
          if (first) {
            if (it.label != "1") {
              //Shift Type no Full Day
              item.initCount = 0.5;
            } else {
              //Shift Type is Full Day
              item.initCount = 1;
            }
          } else {
            this.itemFilled(item);
          }
          if(this.assignmentTarget.unit ==
            positionFormulaUnit.assignmentsOverallCompletePeriod){
            this.assignmentTarget?.workDayList?.forEach((item2) => {
              if(item2.eleJobTitleWorkDayShiftId != it.value){
                item2.eleJobTitleWorkDayShiftId = it.value
                if (it.relateContent) {
                  item2.fromTime = it.relateContent;
                }
                if (it.relateContent2) {
                  item2.toTime = it.relateContent2;
                }
                item2.shiftType = it.label;
                if (first) {
                  if (it.label != "1") {
                    //Shift Type no Full Day
                    item2.initCount = 0.5;
                  } else {
                    //Shift Type is Full Day
                    item2.initCount = 1;
                  }
                } else {
                  this.itemFilled(item2);
                }
              }
              if(!item2.eleJobTitleAssignmentId){
                // no eleJobTitleAssignment unallocated
                item2.initCount = 0
              }
            });
          }
        }
      });
    },
    checkboxChange(val) {
      if (val) {
        this.isShowWorkDateTimeRequired = false;
      } else {
        if (
          this.assignmentTarget.workDayList.filter((item) => item.isCheck)
            .length == 0
        ) {
          this.isShowWorkDateTimeRequired = true;
        }
      }
    },
    itemFilled(item) {
      let canAdd =
        item.isCheck &&
        item.isValid &&
        item.fromTime &&
        item.toTime &&
        item.fromTime != this.INVALID_DATE &&
        item.toTime != this.INVALID_DATE &&
        item.toTime > item.fromTime;
      if (
        this.assignmentTarget.unit == positionFormulaUnit.hoursPerDay ||
        this.assignmentTarget.unit == positionFormulaUnit.hoursOverall
      ) {
        return (
          canAdd
            ? item.filled -
              item.eleJobTitleAssignmentFilled +
              (item.toTime - item.fromTime) / 3600000
            : item.filled
        ).toFixed(2);
      } else {
        //Shift Type is Full Day
        let conut = 1;
        if (item.shiftType != "1") {
          //Shift Type no Full Day
          conut = 0.5;
        }
        return canAdd ? item.filled - item.initCount + conut : item.filled;
      }
    },
    onEndTimeChange(item) {
      setTimeout(() => {
        this.$refs[
          `startTime-${item.eleJobTitleAssignmentTargetId}`
        ][0].validate();
      }, 100);
    },
    onStartTimeChange(item) {
      this.$refs[`endTime-${item.eleJobTitleAssignmentTargetId}`][0].validate();
    },
    checkValid() {
      if (this.AssigndWorkerConfiguration == 2) {
        return this.$refs.observer.validate();
      }

      if (this.multiAssignmentPosition) {
        return this.$refs.observer.validate() && this.checkValidDetialMulti();
      }
      return this.$refs.observer.validate() && this.checkValidDetial();
    },
    async checkValidDetialMulti() {
      let verify_int = 0;
      for (const index in this.assignmentTargetMulti) {
        if (
          await this.$refs[`FormWorkDateTime${index}`][0].checkValidDetial()
        ) {
          verify_int += 1;
        } else {
          verify_int -= 1;
        }
      }
      return verify_int == this.assignmentTargetMulti.length;
    },
    checkValidDetial() {
      let isOverTheTarget = false;
      let valid = true;
      if (this.multiAssignmentPosition) {
        valid = this.checkValidDetialMulti();
        return valid;
      }
      if (
        this.assignmentTarget.unit == positionFormulaUnit.hoursPerDay ||
        this.assignmentTarget.unit == positionFormulaUnit.hoursOverall
      ) {
        this.assignmentTarget.workDayList.forEach((item) => {
          if (
            item.isCheck &&
            item.filled -
              item.eleJobTitleAssignmentFilled +
              (item.toTime - item.fromTime) / 3600000 >
              item.target
          ) {
            this.$refs.lblMsg.message(
              repResult.faild,
              this.$g("overTheTarget")
            );
            isOverTheTarget = true;
          }
        });
      } else {
        if (this.totalFilled > this.assignmentTarget.target) {
          this.$refs.lblMsg.message(repResult.faild, this.$g("overTheTarget"));
          isOverTheTarget = true;
        }
      }
      if (
        this.assignmentTarget.workDayList.filter((item) => item.isCheck)
          .length == 0
      ) {
        this.isShowWorkDateTimeRequired = true;
        return false;
      }
      if (isOverTheTarget) {
        return false;
      } else {
        return this.$refs.observer.validate();
      }
    },
    getFormData() {
      if (this.multiAssignmentPosition) {
        let requestModel = { requestModel: [] };
        this.assignmentTargetMulti.forEach((c) => {
          requestModel.requestModel.push({
            electionId: this.formData.electionId,
            eleJobId: this.formData.eleJobId,
            elePollingPlaceId: c.elePollingPlaceId,
            pollingPlaceTypeId: c.pollingPlaceTypeId,
            unit: c.unit,
            workDayList: c.workDayList.filter((item) => item.isCheck),
          });
        });
        return requestModel;
      }
      this.formData.pollingPlaceTypeId =
        this.assignmentTarget.pollingPlaceTypeId;
      this.formData.unit = this.assignmentTarget.unit;
      this.formData.workDayList = this.assignmentTarget.workDayList.filter(
        (item) => item.isCheck
      );
      return this.formData;
    },
    bindWorkLocationOptions(val) {
      this.formData.elePollingPlaceId = "";
      this.assignmentTargetMulti = [];
      this.isShowWorkDateTime = false;
      if (
        this.formData.electionId &&
        this.formData.eleJobId &&
        this.formData.placeType
      ) {
        getWorkLocationList({
          electionId: this.formData.electionId,
          placeType: this.formData.placeType,
          eleJobId: this.formData.eleJobId,
        }).then((resp) => {
          this.workLocationOptions = resp.data || [];
        });
      } else {
        this.formData.elePollingPlaceId = "";
        this.workLocationOptions = [];
      }
    },
    onWorkLocationRemove(v) {
      console.log(v, this.assignmentTargetMulti);
      this.assignmentTargetMulti.splice(
        this.assignmentTargetMulti.findIndex((c) => c.id == v.value),
        1
      );
      if (
        this.multiAssignmentPosition &&
        this.formData.elePollingPlaceId.length == 1
      ) {
        this.$refs.workLocation.curOptions.forEach((c) => {
          c.$isDisabled = false;
        });
      }
    },
    onWorkLocationChange(v) {
      if (this.onlyPosition) {
        this.getAssignmentTargetForEdit(v);
      } else {
        this.initAssignmentTarget(v);
      }
    },
    setMessage(message) {
      this.$refs.lblMsg.message(repResult.faild, message);
    },
    getAssignmentTargetForEdit() {
      getAssignmentTargetForEdit({
        eleJobId: this.formData.eleJobId,
        elePollingPlaceId: this.formData.elePollingPlaceId,
      }).then((resp) => {
        if (this.multiAssignmentPosition) {
          this.assignmentTargetMulti = resp.data;
          this.assignmentTargetMulti.forEach((c) => {
            c.workDayList.forEach((item) => {
              if (!item.fromTime) {
                item.fromTime = null;
              }
              if (!item.toTime) {
                item.toTime = null;
              }
              let isbool =
                item.eleJobTitleAssignmentId ||
                c.unit == positionFormulaUnit.assignmentsOverallCompletePeriod
                  ? true
                  : false;
              this.$set(item, "isCheck", isbool);
            });
            // if (
            //   c.unit == positionFormulaUnit.assignmentsOverallCompletePeriod &&
            //   c.workDayList.every((item) => {
            //     return (
            //       !item.eleJobTitleAssignmentId ||
            //       item.eleJobTitleAssignmentId ==
            //         "00000000-0000-0000-0000-000000000000"
            //     );
            //   })
            // ) {
            //   c.filled += 1;
            // }
          });
        } else {
          this.assignmentTarget = resp.data[0];
          this.assignmentTarget.workDayList.forEach((item) => {
            if (!item.fromTime) {
              item.fromTime = null;
            }
            if (!item.toTime) {
              item.toTime = null;
            }
            let isbool =
              item.eleJobTitleAssignmentId ||
              this.assignmentTarget.unit ==
                positionFormulaUnit.assignmentsOverallCompletePeriod
                ? true
                : false;
            this.$set(item, "isCheck", isbool);
            this.$set(item, "shiftType", null);
            if (isbool) {
              this.eleJobTitleWorkDayShiftChange(item, true);
            } else {
              item.initCount = 0;
              this.workDateShiftList.forEach((it) => {
                if (item.eleJobTitleWorkDayShiftId == it.value) {
                  item.shiftType = it.label;
                }
              });
            }
          });
          // if (
          //   this.assignmentTarget.unit ==
          //     positionFormulaUnit.assignmentsOverallCompletePeriod &&
          //   this.assignmentTarget.workDayList.every((item) => {
          //     return (
          //       !item.eleJobTitleAssignmentId ||
          //       item.eleJobTitleAssignmentId ==
          //         "00000000-0000-0000-0000-000000000000"
          //     );
          //   })
          // ) {
          //   this.assignmentTarget.filled += 1;
          // }
        }
      });
    },
    initAssignmentTarget(v) {
      getAssignmentTarget({
        eleJobId: this.formData.eleJobId,
        elePollingPlaceId: v.value,
      }).then((resp) => {
        if (this.multiAssignmentPosition) {
          resp.data.forEach((c) => {
            this.assignmentTargetMulti.push({
              assignmentTarget: c,
              title: v.text,
              id: v.value,
              elePollingPlaceId: c.elePollingPlaceId,
              pollingPlaceTypeId: c.pollingPlaceTypeId,
              workDayList: c.workDayList,
            });
          });
        } else {
          this.assignmentTarget = resp.data[0];
          this.assignmentTarget.workDayList.forEach((item) => {
            if (!item.fromTime) {
              item.fromTime = null;
            }
            if (!item.toTime) {
              item.toTime = null;
            }
            let isbool =
              item.eleJobTitleAssignmentId ||
              this.assignmentTarget.unit ==
                positionFormulaUnit.assignmentsOverallCompletePeriod
                ? true
                : false;
            this.$set(item, "isCheck", isbool);
            this.$set(item, "shiftType", null);
            if (isbool) {
              this.eleJobTitleWorkDayShiftChange(item, true);
            } else {
              item.initCount = 0;
              this.workDateShiftList.forEach((it) => {
                if (item.eleJobTitleWorkDayShiftId == it.value) {
                  item.shiftType = it.label;
                }
              });
            }
          });
          // if (
          //   this.assignmentTarget.unit ==
          //     positionFormulaUnit.assignmentsOverallCompletePeriod &&
          //   this.assignmentTarget.workDayList.every((item) => {
          //     return (
          //       !item.eleJobTitleAssignmentId ||
          //       item.eleJobTitleAssignmentId ==
          //         "00000000-0000-0000-0000-000000000000"
          //     );
          //   })
          // ) {
          //   this.assignmentTarget.filled += 1;
          // }
        }
      });
    },
  },
  mounted() {
    getAssigndWorkerConfiguration().then((res) => {
      this.AssigndWorkerConfiguration = res.data;
    });
    this.formData = {
      ...this.propsData,
    };
    if (
      !this.formData.pollingPlaceTypeId ||
      this.formData.pollingPlaceTypeId == "00000000-0000-0000-0000-000000000000"
    ) {
      this.onlyPosition = false;
      getLocationTypeList().then((res) => {
        this.locationTypeOptions = res.data;
      });
    } else {
      this.getAssignmentTargetForEdit();
    }
    getWorkDateShifts({
      eleJobId: this.formData.eleJobId,
    }).then((resp) => {
      this.workDateShiftList = resp.data || [];
    });
  },
};
</script>
<style lang="scss" scoped>
.wapSate {
  > div,
  > span {
    flex-shrink: 0;
  }
}
</style>
