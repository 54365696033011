var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgroundGray"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('KValidationObserver',{ref:"observer",staticClass:"content-page"},[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"election","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.electionList},on:{"change":_vm.getElectionId},model:{value:(_vm.formData.electionId),callback:function ($$v) {_vm.$set(_vm.formData, "electionId", $$v)},expression:"formData.electionId"}})]}}])})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"position","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{ required: true },"slim":"","name":"position"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.positionOptions},on:{"change":_vm.bindWorkLocationOptions},model:{value:(_vm.formData.eleJobId),callback:function ($$v) {_vm.$set(_vm.formData, "eleJobId", $$v)},expression:"formData.eleJobId"}})]}}])})],1),(
        _vm.getConfigVal(
          _vm.curPageConfigAndDefaultPageConfig,
          'positionCodeInput',
          'vIf'
        )
      )?_c('k-form-group',{attrs:{"label-suffix":"positionCode","id":"positionCodeInput","content-cols-lg":"6","content-cols-md":"7","label-align-md":"right","label-cols-md":"3","label-for":"noneBind"}},[_c('k-form-input',{staticClass:"mt-lg-2",attrs:{"plaintext":true},model:{value:(_vm.positionCode),callback:function ($$v) {_vm.positionCode=$$v},expression:"positionCode"}})],1):_vm._e(),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"locationType","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{ required: true },"slim":"","name":"locationType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.locationTypeOptions},on:{"change":_vm.bindWorkLocationOptions},model:{value:(_vm.pollingPlaceTypeId),callback:function ($$v) {_vm.pollingPlaceTypeId=$$v},expression:"pollingPlaceTypeId"}})]}}])})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"workLocation","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{ required: true },"slim":"","name":"workLocation"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasWorkLocation),expression:"hasWorkLocation"}],ref:"workLocation",attrs:{"multiple":_vm.multiAssignmentPosition,"asyn-search-api":_vm.WorkLocationListApi,"expand-query":{
            electionId: _vm.formData.electionId,
            placeType: _vm.pollingPlaceTypeId,
            eleJobId: _vm.formData.eleJobId,
            currentSelectedIds: _vm.currentSelectedIds,
          },"expand-options-attributes":['pollingPlaceTypeId'],"on-item-select":_vm.onWorkLocationChange,"on-item-remove":_vm.onWorkLocationRemove},model:{value:(_vm.formData.elePollingPlaceId),callback:function ($$v) {_vm.$set(_vm.formData, "elePollingPlaceId", $$v)},expression:"formData.elePollingPlaceId"}})]}}])})],1),(_vm.isShowWorkDateTime && !_vm.multiAssignmentPosition)?_c('k-form-group',{attrs:{"label-suffix":"totalFilledTarget","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[(
          _vm.assignmentTarget.unit ==
            _vm.positionFormulaUnit.assignmentsOverallCompletePeriod
        )?_c('div',{staticClass:"col-form-label"},[_c('span',{class:_vm.totalFilledComplete >= _vm.assignmentTarget.target ? 'text-green' : 'text-red'},[_vm._v(_vm._s(_vm.totalFilledComplete))]),_vm._v(" / "+_vm._s(_vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursOverall || _vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursPerDay ? _vm.assignmentTarget.target.toFixed(2) : _vm.assignmentTarget.target)+" "+_vm._s(_vm.assignmentTarget.unitShowTypeStr)+" ")]):_c('div',{staticClass:"col-form-label"},[_c('span',{class:_vm.totalFilled >= _vm.assignmentTarget.target ? 'text-green' : 'text-red'},[_vm._v(_vm._s(_vm.totalFilled))]),_vm._v(" / "+_vm._s(_vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursOverall || _vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursPerDay ? _vm.assignmentTarget.target.toFixed(2) : _vm.assignmentTarget.target)+" "+_vm._s(_vm.assignmentTarget.unitShowTypeStr)+" ")])]):_vm._e(),(_vm.isShowWorkDateTime && !_vm.multiAssignmentPosition)?_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"workDateTime","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"9","content-cols-md":"9"}},[_c('div',{staticClass:"overflow-auto"},[_c('k-alert',{attrs:{"show":_vm.isShowWorkDateTimeRequired,"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$g("validate.required"))+" ")]),_vm._l((_vm.assignmentTarget.workDayList),function(item){return _c('div',{key:item.eleJobTitleAssignmentTargetId},[(item.isCheck || item.filled < item.target)?_c('div',{staticClass:"d-flex align-items-center mb-2 wapSate"},[_c('div',[_c('k-form-checkbox',{attrs:{"disabled":!!(
                    item.eleJobTitleAssignmentId ||
                    !item.isEnable ||
                    _vm.assignmentTarget.unit ==
                    _vm.positionFormulaUnit.assignmentsOverallCompletePeriod
                  )},on:{"change":_vm.checkboxChange},model:{value:(item.isCheck),callback:function ($$v) {_vm.$set(item, "isCheck", $$v)},expression:"item.isCheck"}})],1),_c('div',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(item.workDate)+" ")]),_c('div',{staticClass:"mr-2"},[_c('KValidationProvider',{ref:`startTime-${item.eleJobTitleAssignmentTargetId}`,refInFor:true,attrs:{"name":`startTime-${item.eleJobTitleAssignmentTargetId}`,"rules":item.isCheck
                    ? {
                      required: true,
                      validateTimeFormat: true,
                      validateStartTimeAndEndTime: item.toTime,
                    }
                    : {}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",on:{"onTimeChange":function($event){return _vm.onStartTimeChange(item)}},model:{value:(item.fromTime),callback:function ($$v) {_vm.$set(item, "fromTime", $$v)},expression:"item.fromTime"}})]}}],null,true)})],1),_c('div',{staticClass:"mr-1"},[_vm._v(" - ")]),_c('div',{staticClass:"mr-2 ml-2"},[_c('KValidationProvider',{ref:`endTime-${item.eleJobTitleAssignmentTargetId}`,refInFor:true,attrs:{"name":`endTime-${item.eleJobTitleAssignmentTargetId}`,"rules":item.isCheck
                    ? {
                      required: true,
                      validateTimeFormat: true,
                    }
                    : {}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",on:{"onTimeChange":function($event){return _vm.onEndTimeChange(item)}},model:{value:(item.toTime),callback:function ($$v) {_vm.$set(item, "toTime", $$v)},expression:"item.toTime"}})]}}],null,true)})],1),(_vm.assignmentTarget.showHalfDay)?_c('div',{staticClass:"mr-3"},[_c('k-form-select',{attrs:{"options":_vm.workDateShiftList},on:{"change":function($event){return _vm.eleJobTitleWorkDayShiftChange(item)}},model:{value:(item.eleJobTitleWorkDayShiftId),callback:function ($$v) {_vm.$set(item, "eleJobTitleWorkDayShiftId", $$v)},expression:"item.eleJobTitleWorkDayShiftId"}})],1):_vm._e(),(
                _vm.assignmentTarget.unit != _vm.positionFormulaUnit.hoursOverall &&
                  _vm.assignmentTarget.unit !=
                  _vm.positionFormulaUnit.assignmentsOverallCompletePeriod &&
                  _vm.assignmentTarget.unit !=
                  _vm.positionFormulaUnit.assignmentsOverallDayToDay
              )?_c('div',[_c('span',{class:parseFloat(_vm.itemFilled(item)) >= item.target
                    ? 'text-green'
                    : 'text-red'},[_vm._v(_vm._s(_vm.itemFilled(item)))]),_vm._v(" / "+_vm._s(_vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursOverall || _vm.assignmentTarget.unit == _vm.positionFormulaUnit.hoursPerDay ? item.target.toFixed(2) : item.target)+" "+_vm._s(_vm.assignmentTarget.unitShowTypeStr)+" ")]):_vm._e()]):_vm._e()])})],2)]):_vm._e(),_vm._l((_vm.assignmentTargetMulti),function(item,index){return (_vm.multiAssignmentPosition)?[_c('FormWorkDateTime',{key:index,ref:`FormWorkDateTime${index}`,refInFor:true,attrs:{"assignment-target":item.assignmentTarget,"title":item.title,"position-formula-unit":_vm.positionFormulaUnit}})]:_vm._e()})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }