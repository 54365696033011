<template>
  <div class="backgroundGray">
    <KValidationObserver ref="observer" class="content-page">
      <KValidationProvider
        :rules="{
          required: func.reasonsRequired,
          max: func.reasonsFree ? 2000 : null,
        }"
        v-slot="{ errors }"
        slim
        name="reason"
      >
        <k-form-group
          :label-class="func.reasonsRequired ? `require-mark` : ``"
          :label="func.reasonTitle"
          :no-locale="true"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-textarea
            v-if="func.reasonsFree"
            v-model="reason"
            type="text"
          />
          <k-form-select v-else v-model="reasonId" :options="func.reasons" />
        </k-form-group>
      </KValidationProvider>
    </KValidationObserver>
  </div>
</template>

<script>
export default {
  props: {
    func: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      reasonId: null,
      reason: "",
    };
  },
  methods: {
    checkValid() {
      return this.$refs.observer.validate();
    },
    getReasonId() {
      return this.reasonId;
    },
    getReason() {
      return this.reason;
    },
  },
  mounted() {
    if (this.func.reasonsFree) {
      document.querySelectorAll("textarea").forEach((item) => {
        item.maxLength = 2000;
      });
    }
  },
};
</script>
